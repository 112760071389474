import React from "react";

import { Link } from "@reach/router";
import UserAvatar from "react-user-avatar";
import numeral from "numeral";

import Toolbar from "components/ui/Toolbar";
import { Box } from "components/ui/bulma/elements";
import { Level, Left, Right, Item } from "components/ui/bulma/layout";

import formatDate from "utils/formatDate";
import customerName from "utils/customerName";

export default function Merchant({ data, view, actions }) {
  switch (view) {
    case "large":
      return <Large data={data} actions={actions} />;

    case "grid":
      return <Grid data={data} actions={actions} />;

    case "table":
      return <Table data={data} actions={actions} />;

    default:
      return null;
  }
}

const Large = ({
  data: merchant,
  data: { id, name, paymentCount, avgTransaction, totalVolume },
  actions: [setItem]
}) => {
  const buttons = [{ link: "merchants", icon: "search-plus" }];
  return (
    <Box color="white">
      <article className={`message`}>
        <div className="message-body">
          <Level>
            <Left>
              {name && (
                <Item>
                  <Link to={`/merchants/${id}`}>
                    <UserAvatar
                      size="64"
                      name={name}
                      colors={["#ccc", "#aaa", "#ddd", "#FFF"]}
                    />
                  </Link>
                </Item>
              )}
              <Item>
                <Link to={`/merchants/${id}`}>
                  <p className="title is-4">{name}</p>
                  {/* <p className="subtitle is-6">{customer.email}</p> */}
                </Link>
              </Item>
            </Left>
            <Right>
              <Item>
                <div className="has-text-centered">
                  <p className="heading">transactions</p>
                  <p className="title">{paymentCount}</p>
                </div>
              </Item>
              <Item />
              <Item>
                <div className="has-text-centered">
                  <p className="heading">volume</p>
                  <p className="title">
                    {totalVolume ? (
                      <span>
                        <small>$</small>
                        {numeral(totalVolume).format("0,0")}
                      </span>
                    ) : (
                      "0"
                    )}
                  </p>
                </div>
              </Item>
              <Item />
              <Item>
                <div className="has-text-centered">
                  <p className="heading">Avg</p>
                  <p className="title">
                    {avgTransaction ? (
                      <span>
                        <small>$</small>
                        {numeral(avgTransaction).format("0,0")}
                      </span>
                    ) : (
                      "-"
                    )}
                  </p>
                </div>
              </Item>
            </Right>
          </Level>
        </div>
      </article>
      <Toolbar itemID={id} buttons={buttons} color="primary" />
    </Box>
  );
};

const Grid = ({ data, actions }) => {
  const [setItem] = actions;
  const buttons = [{ link: "merchants", icon: "search-plus" }];

  return (
    <div className="column is-narrow">
      <Box color="white-bis">
        <article className="media">
          {data.name && (
            <div className="media-left">
              <figure className="image is-64x64">
                <UserAvatar
                  size="64"
                  name={data.name}
                  colors={["#ccc", "#aaa", "#ddd"]}
                />
              </figure>
            </div>
          )}
          <div className="media-content">
            <div className="content">
              <p className="title is-5">{data.name}</p>
              {/* <p className="subtitle is-6">{data.email}</p> */}
            </div>
            <Toolbar
              itemID={data.id}
              buttons={buttons}
              color="primary"
              size="small"
            />
          </div>
        </article>
      </Box>
    </div>
  );
};

const Table = ({ data, actions: [setItem] }) => {
  const buttons = [{ link: "merchants", icon: "search-plus" }];
  return (
    <tr>
      <td>
        {data.name && (
          <UserAvatar
            size="48"
            name={data.name}
            colors={["#ccc", "#aaa", "#ddd"]}
          />
        )}
      </td>
      <td>
        <p className="title is-5">{data.name}</p>
        <p className="subtitle is-6">{data.email}</p>
      </td>

      <td>{data.phone}</td>
      <td>{data.transactions}</td>
      <td>${Number(data.totalVolume).toFixed()}</td>
      <td>${Number(data.avgTransaction).toFixed()}</td>
      <td>{formatDate(data.latestTransaction, "date")}</td>
      <td>
        <Toolbar itemID={data.id} buttons={buttons} color="primary" />
      </td>
    </tr>
  );
};
