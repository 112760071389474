import React, { useEffect } from 'react';

import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { Router } from '@reach/router';

import { ModalProvider } from 'react-modal-hook';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAppAuth } from 'services/firebase';

import Layout from 'components/ui/Layout';
import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

// import CampaignSetup from 'components/CampaignWizard';

// **** Pages ****
import Dashboard from 'pages/Dashboard';
import Merchants from 'pages/Merchants';
import Transactions from 'pages/Transactions';

// **** Profiles ****
import MerchantProfile from 'components/profiles/Merchant';
import TransactionProfile from 'components/profiles/Transaction';

// **** Forms ****
import AccountSetup from 'components/forms/AccountSetup';
import ActivateAccount from 'components/forms/Activate';

import UserContext from 'context/UserContext';

// TODO ******** TEST UID ****** CLEAR STRING TO CANCEL *******

// const TEST_UID = "5c0056ca9158ca0015a2ea41"; // Matt
// const TEST_UID = '5bd989afc6fffa00153b0920'; // Dan
// const TEST_UID = '5d13a218d311540017956663'; // Auto Glass
const TEST_UID = '';

// TODO *******************************************************

export default function Account() {
	let headers;
	const [ user ] = useAuthState(firebaseAppAuth);

	if (process.env.NODE_ENV === 'development' && TEST_UID) {
		headers = { Authorization: `${TEST_UID}` };
	} else {
		headers = { Authorization: `${user.uid}` };
	}

	const { loading, data, error } = useQuery(CURRENT_USER, {
		context: { headers }
	});

	useEffect(
		() => {
			if (user && data && data.platform)
				window.analytics.identify(user.uid, {
					platform: 'unmerchant',
					name: user.displayName,
					email: user.email,
					company: {
						id: data.platform.id,
						name: data.platform.name
					},
					createdAt: user.metadata.creationTime
				});
		},
		[ user, data ]
	);

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	if (data) {
		const { platform } = data;

		return (
			<UserContext.Provider value={{ user, platform, headers }}>
				{!platform ? (
					<AccountSetup />
				) : (
					<ModalProvider>
						<Router>
							<Layout path="/">
								<Dashboard path="/" />

								<Transactions path="transactions" />
								<TransactionProfile path="activity/:itemID" />

								<Merchants path="merchants" />
								<MerchantProfile path="merchants/:itemID" />

								<ActivateAccount path="/activate" />
							</Layout>
							<Layout default />
						</Router>
					</ModalProvider>
				)}
			</UserContext.Provider>
		);
	}

	return null;
}

// export default gql`
//   mutation ActivateMerchant($merchantProps: MerchantProps) {
//     activateMerchant(merchantProps: $merchantProps) {
//       id
//       status
//     }
//   }
// `;

// export default gql`
// 	mutation OnboardMerchant($merchantProps: MerchantProps) {
// 		onboardMerchant(merchantProps: $merchantProps) {
// 			id
// 		}
// 	}
// `;

// export default gql`
//   mutation ActivateMerchant($merchantProps: MerchantProps) {
//     activateMerchant(merchantProps: $merchantProps) {
//       id
//       isLive
//     }
//   }
// `;

// export default gql`
//   mutation UpdateMerchant($merchantProps: MerchantProps) {
//     updateMerchant(merchantProps: $merchantProps) {
//       id
//     }
//   }
// `;

const CURRENT_USER = gql`
	query CurrentUser {
		platform {
			id
			name
			apiKey
			status
			isLive
		}
	}
`;
