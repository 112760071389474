import React, { useContext, useRef, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useModal } from 'react-modal-hook';
import gql from 'graphql-tag';

import UserAvatar from 'react-user-avatar';
import { Box, Notification } from 'components/ui/bulma';

import CustomerForm from 'components/forms/CustomerForm';
import PaymentForm from 'components/forms/PaymentForm';

import Hero from 'components/ui/Hero';
import List from 'components/ui/List';
import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

import brandIcon from 'utils/brandIcon';
import formatDate from 'utils/formatDate';
import setColor from 'utils/setColor';
import statusIcon from 'utils/statusIcon';
import customerName from 'utils/customerName';

import UserContext from 'context/UserContext';

export default function Merchant({ itemID }) {
	const componentRef = useRef();
	const { headers } = useContext(UserContext);
	const [ tab, setTab ] = useState('overview');
	const [ view, setView ] = useState('large');
	const [ item, setItem ] = useState(itemID);

	const { data, loading, error } = useQuery(MERCHANT_DETAILS, {
		variables: { id: item },
		context: { headers }
	});

	const [ archive, { loading: archiveLoading } ] = useMutation(ARCHIVE, {
		variables: { id: item },
		context: { headers },
		refetchQueries: [ 'Platform', 'MerchantDetails' ]
	});

	const [ showActivateModal, hideActivateModal ] = useModal(
		() => (
			<div className="modal is-active">
				<div className="modal-background" />
				<div className="modal-content">
					<CustomerForm item={data.consumer} onClose={hideUpdateModal} />
				</div>
			</div>
		),
		[ data ]
	);

	const [ showUpdateModal, hideUpdateModal ] = useModal(
		() => (
			<div className="modal is-active">
				<div className="modal-background" />
				<div className="modal-content">
					<CustomerForm item={data.consumer} onClose={hideUpdateModal} />
				</div>
			</div>
		),
		[ data ]
	);

	const [ showSaveModal, hideSaveModal ] = useModal(
		() => (
			<div className="modal is-active">
				<div className="modal-background" />
				<div className="modal-content">
					<PaymentForm save customer={data.consumer} onClose={hideSaveModal} />
				</div>
			</div>
		),
		[ data ]
	);

	const [ showPaymentModal, hidePaymentModal ] = useModal(
		() => (
			<div className="modal is-active">
				<div className="modal-background" />
				<div className="modal-content">
					<PaymentForm customer={data.consumer} onClose={hidePaymentModal} />
				</div>
			</div>
		),
		[ data ]
	);

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	const { merchant, merchant: { name, email, status, isLive } } = data;

	const buttons = [
		// 	{
		// 	  action: showPaymentModal,
		// 	  icon: "credit-card",
		// 	  text: "charge",
		// 	  color: "dark",
		// 	  disabled: status === "archived"
		// 	},
		// 	{
		// 	  action: showSaveModal,
		// 	  icon: "download",
		// 	  text: "Save Card",
		// 	  color: "dark",
		// 	  disabled: status === "archived"
		// 	},
		// 	{
		// 	  action: showUpdateModal,
		// 	  icon: "pencil-alt",
		// 	  text: "Update",
		// 	  color: "dark",
		// 	  disabled: status === "archived"
		// 	},
		// 	{
		// 		action: archive,
		// 		icon: 'pause',
		// 		text: 'Pause',
		// 		color: 'warning',
		// 		loading: archiveLoading,
		// 		disabled: true
		// 	},
		// 	{
		// 		action: archive,
		// 		icon: 'archive',
		// 		text: 'Archive',
		// 		color: 'danger',
		// 		loading: archiveLoading,
		// 		disabled: true
		// 	}
	];

	return (
		<div>
			<Hero
				item={item}
				color="light"
				// avatar={avatar}
				title={name}
				subtitle={email}
				tabs={tabs}
				activeTab={tab}
				setTab={setTab}
				view={view}
				setView={setView}
				toolbar={buttons}
			/>

			<Profile tab={tab} actions={[ setItem ]} view={view} data={data} />
		</div>
	);
}

const Profile = ({ tab, view, actions: [ setItem ], data: { merchant } }) => {
	const { payments, users, invoices, subscriptions, paymentCount, totalVolume, avgTransaction } = merchant;

	switch (tab) {
		case 'overview':
			return (
				<div>
					<nav className="level">
						<div className="level-item has-text-centered">
							<div>
								<p className="heading">purchases</p>
								<p className="title">{paymentCount}</p>
							</div>
						</div>
						<div className="level-item has-text-centered">
							<div>
								<p className="heading">Total</p>
								<p className="title">
									{totalVolume ? (
										<span>
											<small>$</small>
											{Number(totalVolume).toFixed(2)}
										</span>
									) : (
										'0'
									)}
								</p>
							</div>
						</div>

						<div className="level-item has-text-centered">
							<div>
								<p className="heading">Avg purchase</p>
								<p className="title">
									{avgTransaction ? (
										<span>
											<small>$</small>
											{Number(avgTransaction).toFixed(2)}
										</span>
									) : (
										'-'
									)}
								</p>
							</div>
						</div>
					</nav>
				</div>
			);

		case 'company':
			return <Company view={view} data={merchant} actions={[ setItem ]} />;
		case 'contacts':
			return <Contacts data={users} actions={[ setItem ]} />;
		case 'transactions':
			return <List type="activity" view={view} data={payments} actions={[ setItem ]} />;
		case 'invoices':
			return <List type="invoices" view={view} data={invoices} actions={[ setItem ]} />;
		case 'scheduled':
			return <List type="scheduled payments" view={view} data={subscriptions} actions={[ setItem ]} />;

		default:
			return null;
	}
};

const Company = ({ data: { entity, type, taxID, address, phone, email } }) => (
	<Box>
		<p>
			Legal entity: <strong>{entity}</strong>
		</p>
		<p>
			Type: <strong>{type}</strong>
		</p>
		<p>
			Tax ID: <strong>{taxID}</strong>
		</p>
		<p>
			Address: <strong>{address}</strong>
		</p>
		<p>
			Phone:{' '}
			<strong>
				<a href={`tel:${phone}`}>{phone}</a>
			</strong>
		</p>
		<p>
			Email:{' '}
			<strong>
				<a href={`mailto:${email}`}>{email}</a>
			</strong>
		</p>
	</Box>
);

const Contacts = ({ data }) => (
	<div>
		{data.map(({ id, firstName, lastName, email, phone, avatar }) => (
			<Box key={id}>
				<figure className="image is-64x64">
					<UserAvatar size="64" name={firstName} colors={[ '#ccc', '#aaa', '#ddd' ]} />
				</figure>
				<br />
				<p className="title">
					<strong>
						{firstName} {lastName}
					</strong>
				</p>
				<p>
					Phone:{' '}
					<strong>
						<a href={`tel:${phone}`}>{phone}</a>
					</strong>
				</p>
				<p>
					Email:{' '}
					<strong>
						<a href={`mailto:${email}`}>{email}</a>
					</strong>
				</p>
			</Box>
		))}
	</div>
);

const Cards = ({ data }) => {
	if (!data || data.length < 1)
		return (
			<Notification color="dark">
				<strong>{`No saved cards found. Please check back later!`}</strong>
			</Notification>
		);
	return (
		<div className="columns is-multiline is-mobile">
			{data.map(({ id, brand, last4, status, expMM, expYY }) => {
				if (brand)
					return (
						<div className="column is-narrow" key={id}>
							<Box color="white-bis">
								<article className="media">
									<div className="media-left">
										<span className="icon is-large has-text-black">
											<i className={`fab fa-3x fa-${brandIcon(brand)}`} />
										</span>
									</div>
									<div className="media-content">
										<div className="content">
											<p className="title is-4">{last4}</p>
											<p className="subtitle is-6">
												{expMM}/{expYY}
											</p>
										</div>
									</div>
								</article>
								{/* <Toolbar itemID={id} buttons={buttons} color="primary" size="small" /> */}
							</Box>
						</div>
					);
			})}
		</div>
	);
};

const tabs = [
	{ title: 'overview', icon: 'address-card' },
	{ title: 'company', icon: 'award' },
	{ title: 'contacts', icon: 'users' },
	{ title: 'transactions', icon: 'list' }
	// { title: 'invoices', icon: 'paperclip' },
	// { title: 'scheduled', icon: 'clock' }
	//   { title: "cards", icon: "credit-card" }
];

// *****************************************
// **************** GraphQL ****************
// *****************************************

const MERCHANT_DETAILS = gql`
	query MerchantDetails($id: ID!) {
		merchant(id: $id) {
			id
			status
			isLive
			email
			name
			phone
			address
			entity
			type
			taxID
			updatedAt
			ticket
			volume
			users {
				id
				firstName
				lastName
				email
				avatar
				phone
			}
			payments {
				id
				date
				amount
				currency
				status
				isLive
				authCode
				consumer {
					id
					email
					firstName
					lastName
				}
				card {
					id
					brand
					last4
				}
			}
			invoices {
				id
				status
				amount
				dueDate
				description
				consumer {
					id
					email
				}
				merchant {
					id
					name
				}
			}
			subscriptions {
				id
				amount
				status
				startDate
				currency
				frequency
				lastRun
				nextRun
				consumer {
					id
					email
					firstName
					lastName
				}
			}
			totalVolume
			paymentCount
			avgTransaction
		}
	}
`;

const ARCHIVE = gql`
	mutation ArchiveCustomer($id: ID!) {
		archiveConsumer(id: $id) {
			id
		}
	}
`;
