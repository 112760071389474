import React, { useContext } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import UserAvatar from "react-user-avatar";
import numeral from "numeral";

import Toolbar from "components/ui/Toolbar";
import {
  Columns,
  Column,
  Tiles,
  Parent,
  Icon,
  Tile,
  Level,
  Box,
  Left,
  Right
} from "../components/ui/bulma";

import Spinner from "components/ui/Spinner";
import Error from "components/ui/Error";

import brandIcon from "utils/brandIcon";
import formatDate from "utils/formatDate";

import UserContext from "context/UserContext";
import { cardTypes } from "react-payment-inputs/lib/utils/cardTypes-dfd289eb";

export default function Dashboard() {
  const { headers, platform } = useContext(UserContext);
  const { data, loading, error } = useQuery(PLATFORM, {
    context: { headers }
  });

  if (loading) return <Spinner />;
  if (error) return <Error error={error} />;

  const {
    platform: {
      totalVolume,
      revenue,
      avgTransaction,
      merchantCount,
      pendingCount,
      newCount,
      paymentCount,
      merchants,
      payments,
      pendingMerchants
    }
  } = data;

  return (
    <>
      <Tiles>
        <Parent vertical>
          <Parent>
            <Tile box color="black">
              <p className="subtitle is-3">Accounts</p>
              <p className="title is-1">{merchantCount}</p>
              {/* <p className="subtitle">Your total sales volume</p> */}
            </Tile>
            <Tile box color="dark">
              <p className="subtitle is-3">Volume</p>
              <p className="title is-1">
                <small>$</small>0
                {/* {totalVolume ? numeral(totalVolume).format("0,0") : 0} */}
              </p>
              {/* <p className="subtitle">Your total sales volume</p> */}
            </Tile>
            <Tile box color="light">
              <p className="subtitle is-3">Transactions</p>
              <p className="title is-1">
                {paymentCount ? numeral(paymentCount).format("0,0") : 0}
              </p>

              {/* <p className="subtitle">Payments, invoices and more</p> */}
            </Tile>
            <Tile box color="white">
              <p className="subtitle is-3">Revenue</p>
              <p className="title is-1">
                <small>$</small>0
                {/* {revenue ? numeral(revenue).format("0,0") : 0} */}
              </p>

              {/* <p className="subtitle">Average sales ticket across all transactions</p> */}
            </Tile>
          </Parent>
        </Parent>
      </Tiles>


      <Tiles>
        <div className="tile is-vertical is-8">
          <div className="tile">
            <div className="tile is-parent is-vertical">
              <Tile box color="light">
                <p className="subtitle is-3">New accounts</p>
                <p className="title is-1">{newCount}</p>
              </Tile>
              <Tile box color="light">
                <p className="subtitle is-3">Activated</p>
                <p className="title is-1">{pendingCount}</p>
              </Tile>
            </div>
            <div className="tile is-parent">
              <article className="tile is-child box">
                <p className="title">Activity</p>
                <Activity data={payments} />
              </article>
            </div>
          </div>
          <div className="tile is-parent">
            <article className="tile is-child box">
              <p className="title">Pending approval</p>
              <PendingMerchants data={pendingMerchants} />
            </article>
          </div>
        </div>
        <div className="tile is-parent">
          <article className="tile is-child box">
            <div className="content">
              <p className="title">Top accounts</p>

              <Merchants data={merchants} />
            </div>
          </article>
        </div>
      </Tiles>
    </>
  );
}

const Merchants = ({ data: merchants }) => {
  const buttons = [
    { link: "merchants", icon: "search-plus", color: "primary" }
  ];
  return (
    <div>
      {merchants
        .filter(merchant => Number(merchant.totalVolume) > 1000)
        .slice(0, 9)
        .map(({ id, name, category, totalVolume }) => (
          <Tile key={id} box>
            <Level mobile>
              <Left>
                <div>
                  <p className="title is-size-6">{name}</p>
                  <p className="subtitle is-size-6">{category}</p>
                </div>
              </Left>
              <Right>
                <strong>
                  <small>$</small>
                  {Number(totalVolume).toFixed(0)}
                </strong>
                <Toolbar buttons={buttons} itemID={id} size="small" />
              </Right>
            </Level>
          </Tile>
        ))}
    </div>
  );
};
const PendingMerchants = ({ data: pendingMerchants }) => {
  const buttons = [
    { link: "merchants", icon: "search-plus", color: "primary" }
  ];
  return (
    <Columns multiline mobile>
      {pendingMerchants.reverse().map(({ id, name, category }) => (
        <div key={id} className="column is-narrow">
          <Box color="white-bis">
            {name && (
              <figure className="image is-64x64">
                <UserAvatar
                  size="64"
                  name={name}
                  colors={["#ccc", "#aaa", "#ddd"]}
                />
              </figure>
            )}
            <br></br>
            <p className="title is-6">{name}</p>
            <Toolbar buttons={buttons} itemID={id} size="small" />
          </Box>
        </div>
      ))}
    </Columns>
  );
};
const Activity = ({ data: payments }) => {
  const buttons = [
    { link: "transactions", icon: "search-plus", color: "primary" }
  ];
  return (
    <div>
      {payments.slice(0, 4).map(({ id, amount, card, status, merchant }) => (
        <Tile key={id} box>
          <Level mobile>
            <Left>
              <div>
                <p className="title is-size-6">
                  <small>$</small>
                  {Number(amount).toFixed(2)}
                </p>
                <p className="subtitle is-size-6">{merchant.name}</p>
              </div>
            </Left>
            <Right>
              <Icon brand name={brandIcon(card.brand)} size="lg" color="dark" />
            </Right>
          </Level>
        </Tile>
      ))}
    </div>
  );
};

// *****************************************
// **************** GraphQL ****************
// *****************************************

const PLATFORM = gql`
  query Platform {
    platform {
      id
      totalVolume
      revenue
      merchants {
        id
        name
        category
        totalVolume
      }
      pendingMerchants: merchants(status: "pending") {
        id
        status
        name
      }
      merchantCount
      paymentCount
      consumerCount
      approvedCout: merchantCount(status: "approved")
      pendingCount: merchantCount(status: "pending")
      newCount: merchantCount(status: "new")
      payments {
        id
        status
        amount
        merchant {
          id
          name
        }
        card {
          id
          brand
          last4
        }
      }
    }
  }
`;
