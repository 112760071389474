import React from "react";

import { Link } from "@reach/router";
import UserAvatar from "react-user-avatar";
import numeral from "numeral";

import Toolbar from "components/ui/Toolbar";
import StatusTag from "components/ui/StatusTag";
import { Box, Icon } from "components/ui/bulma/elements";
import { Level, Left, Right, Item } from "components/ui/bulma/layout";

import brandIcon from "utils/brandIcon";
import formatDate from "utils/formatDate";
import setColor from "utils/setColor";
import statusIcon from "utils/statusIcon";
import customerName from "utils/customerName";

export default function Activity({ data, view, actions }) {
  switch (view) {
    case "large":
      return <Large data={data} actions={actions} />;
    case "grid":
      return <Grid data={data} actions={actions} />;
    case "table":
      return <Table data={data} actions={actions} />;
    default:
      return null;
  }
}

const Large = ({
  data: payment,
  data: { id, date, amount, status, consumer },
  actions: [setItem]
}) => {
  const buttons = [{ link: "activity", icon: "search-plus" }];
  return (
    <Box color="white">
      <article className={`message is-${setColor(status)}`}>
        <div className="message-body">
          <Level>
            <Left>
              <Item>
                <StatusTag type="transaction" item={payment} size="medium" />
              </Item>
            </Left>
            <Right>
              <Item>
                <p className="subtitle">{formatDate(date)}</p>
              </Item>
            </Right>
          </Level>
          <Level>
            <Left>
              <Item>
                <p className="title is-1">
                  <small>$</small>
                  {amount}
                </p>
              </Item>
            </Left>
            {/* <Right>
							<Item>
								<figure className="image is-64x64">
									<UserAvatar
										size="64"
										name={customerName(consumer)}
										src={consumer.avatar}
										colors={[ '#ccc', '#aaa', '#ddd' ]}
									/>
								</figure>
							</Item>
							<Item>
								<Link to={`/customers/${consumer.id}`}>
									<p className="title is-5">{customerName(consumer)}</p>
								</Link>
							</Item>
						</Right> */}
          </Level>
        </div>
      </article>
      <Toolbar itemID={id} buttons={buttons} color="primary" />
    </Box>
  );
};

const Grid = ({ data: { id, date, amount, consumer }, actions }) => {
  const buttons = [{ link: "activity", icon: "search-plus" }];
  return (
    <div className="column is-narrow">
      <Box color="white-bis">
        <article className="media">
          <div className="media-left">
            <p className="title is-1">
              <small>$</small>
              {amount}
            </p>
          </div>
          <div className="media-content">
            <div className="content">
              {/* <Link to={`/customers/${consumer.id}`}>
                <p className="title is-5 has-text-black">
                  {customerName(consumer)}
                </p>
              </Link> */}
              {formatDate(date)}
            </div>
          </div>
        </article>
        <Toolbar itemID={id} buttons={buttons} color="primary" size="small" />
      </Box>
    </div>
  );
};

const Table = ({
  data: {
    id,
    date,
    amount,
    status,
    consumer,
    card: { brand, last4 }
  },
  actions: [setItem]
}) => {
  const buttons = [{ link: "activity", icon: "search-plus" }];
  return (
    <tr>
      <td>
        <Icon
          name={statusIcon(status)}
          color={setColor(status)}
          container="medium"
        />
      </td>
      <td>
        {/* <Link to={`/customers/${consumer.id}`}>
          <p className="title is-6">{customerName(consumer)}</p>
        </Link> */}
      </td>
      <td>{formatDate(date)}</td>
      <td>
        <p className="title is-6">{numeral(amount).format("$0,0.00")}</p>
      </td>
      <td>
        {/* <span>
					<Icon brand name={brandIcon(brand)} container="large" size="lg" color="black" />
					{last4}
				</span> */}
        <span className="icon is-medium">
          <i className={`fab fa-lg fa-${brandIcon(brand)}`} />{" "}
          <small>{last4}</small>
        </span>
      </td>
      <td>
        <Toolbar itemID={id} buttons={buttons} color="primary" size="small" />
      </td>
    </tr>
  );
};
