import React, { useContext, useState } from "react";

import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import Hero from "components/ui/Hero";
import List from "components/ui/List";
import Transaction from "components/profiles/Transaction";

import Spinner from "components/ui/Spinner";
import Error from "components/ui/Error";

import UserContext from "context/UserContext";

export default function Transactions({ itemID }) {
  const { headers } = useContext(UserContext);
  const [view, setView] = useState("large");
  const [tab, setTab] = useState("approved");
  const [item, setItem] = useState(itemID);
  const { loading, data, error } = useQuery(TRANSACTIONS, {
    variables: { status: tab },
    context: { headers }
  });

  if (loading) return <Spinner />;
  if (error) return <Error error={error} />;

  if (item) return <Transaction id={item} />;

  return (
    <div>
      <Hero
        color="primary"
        icon="credit-card"
        title="Transactions"
        subtitle="Real-time transaction feed from all accounts"
        // tabs={tabs}
        // activeTab={tab}
        setTab={setTab}
        view={view}
        setView={setView}
      />
      <List
        type="activity"
        view={view}
        data={data.payments}
        actions={[setItem]}
      />
    </div>
  );
}

const tabs = [
  { title: "approved", icon: "check-circle" },
  { title: "declined", icon: "times-circle" },
  { title: "refunded", icon: "undo-alt" }
];

// *****************************************
// **************** GraphQL ****************
// *****************************************

const TRANSACTIONS = gql`
  query Transactions {
    payments {
      id
      date
      amount
      status
      isLive
      authCode
      card {
        id
        brand
        last4
      }
    }
  }
`;
