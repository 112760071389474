import React, { useContext, useRef, useState } from 'react';

import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { Link } from '@reach/router';
import ReactToPrint from 'react-to-print';
import UserAvatar from 'react-user-avatar';

import Toolbar from 'components/ui/Toolbar';
import StatusTag from 'components/ui/StatusTag';
import { Box, Icon } from 'components/ui/bulma/elements';
import { Level, Left, Right, Item } from 'components/ui/bulma/layout';

import brandIcon from 'utils/brandIcon';
import customerName from 'utils/customerName';
import formatDate from 'utils/formatDate';
import setColor from 'utils/setColor';
import statusIcon from 'utils/statusIcon';

import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

import UserContext from 'context/UserContext';

export default function Transaction({ itemID }) {
	const componentRef = useRef();
	const { headers } = useContext(UserContext);
	const [ tab, setTab ] = useState('overview');
	const [ view, setView ] = useState('large');
	const [ item, setItem ] = useState(itemID);

	const { data, loading, error } = useQuery(PAYMENT_DETAILS, {
		variables: { id: itemID },
		context: { headers }
	});

	const refundProps = { id: itemID, amount: '' };

	const [ refundPayment, { refundData, refundLoading, refundError } ] = useMutation(REFUND_PAYMENT, {
		variables: { refundProps },
		context: { headers },
		refetchQueries: [ 'Account', 'Payments', 'PaymentDetails' ]
	});

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	const buttons = [
		{
			action: refundPayment,
			icon: 'times',
			text: 'refund',
			color: 'danger',
			loading: refundLoading
		}
	];
	
	const { date, authCode, amount, status, isLive, consumer, card } = data.payment;

	return (
		<Box>
			<article ref={componentRef} className={`message is-${setColor(status)}`}>
				<div className="message-body">
					<Level>
						<Left>
							<Item>
								<div>
									<StatusTag type="transaction" item={data.payment} size="medium" />
									<br />
									<h1 className="title is-1">
										<small>$</small>
										{Number(amount).toFixed(2)}
									</h1>
									<h2 className="subtitle">{formatDate(date)}</h2>
									<br />
								</div>
							</Item>
						</Left>
						<Right>
							<Item>
								<Link to={`/customers/${consumer.id}`}>
									<figure className="image is-64x64">
										<UserAvatar
											size="64"
											name={customerName(consumer)}
											src={consumer.avatar}
											colors={[ '#ccc', '#aaa', '#ddd' ]}
										/>
									</figure>
								</Link>
							</Item>
							<Item>
								<p className="title is-4">{customerName(consumer)}</p>
							</Item>
							{card && (
								<Item>
									<Icon
										brand
										name={brandIcon(card.brand)}
										container="large"
										size="2x"
										color="black"
									/>
									<p>{card.last4}</p>
								</Item>
							)}
						</Right>
					</Level>
				</div>
			</article>
			<Level>
				<Left>
					<Item>
						<Toolbar buttons={buttons} color="primary" size="large" />
					</Item>
				</Left>
				<Right>
					<Item>
						<ReactToPrint
							trigger={() => (
								<span className="button is-medium is-dark">
									<span className="icon">
										<i className="fas fa-print" />
									</span>
									<span>Print</span>
								</span>
							)}
							content={() => componentRef.current}
						/>
					</Item>
				</Right>
			</Level>
		</Box>
	);
}

const PAYMENT_DETAILS = gql`
	query PaymentDetails($id: ID!) {
		payment(id: $id) {
			id
			date
			status
			isLive
			amount
			authCode
			consumer {
				id
				firstName
				lastName
				email
				phone
				avatar
			}
			card {
				id
				brand
				last4
			}
		}
	}
`;

const REFUND_PAYMENT = gql`
	mutation RefundPayment($refundProps: RefundProps) {
		refundPayment(refundProps: $refundProps) {
			id
			amount
		}
	}
`;
