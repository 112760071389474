import React from "react";
import { Link } from "@reach/router";

import { Delete, Level, Left, Right } from "./bulma";

// import logo from "assets/images/logo.png";

function Menu({ title, children }) {
  return (
    <aside className="menu is-dark">
      {title && <p className="menu-label">{title}</p>}
      <ul className="menu-list">{children}</ul>
    </aside>
  );
}

function MenuItem({ extended, name, icon, route, close }) {
  if (extended) {
    return (
      <li>
        <Link
          to={route}
          onClick={close}
          getProps={({ isCurrent }) => {
            return isCurrent ? { className: "is-active" } : null;
          }}
        >
          <span className="icon">
            <i className={`fas fa-${icon}`} />
          </span>{" "}
          <strong>{name}</strong>
        </Link>
      </li>
    );
  } else
    return (
      <li className="has-text-centered">
        <Link
          to={route}
          getProps={({ isCurrent }) => {
            return isCurrent ? { className: "has-text-primary" } : null;
          }}
        >
          <span className="icon is-large">
            <i className={`fas fa-${icon} fa-lg`} />
          </span>
          <p className="is-size-7 has-text-weight-semibold">{name}</p>
        </Link>
      </li>
    );
}

export default function SidePanel({ extendedMenu, extendMenu }) {
  return (
    <aside className="menu is-black sticky">
      <div>
        <div className="is-hidden-mobile">
          <ul className="menu-list">
            <MenuItem name="Dashboard" icon="tachometer-alt" route="/" />
            <MenuItem name="Merchants" icon="store" route="/merchants" />
            <MenuItem
              name="Transactions"
              icon="credit-card"
              route="/transactions"
            />
            {/* <MenuItem name="Sales" icon="user-friends" route="/sales" /> */}
            {/* <MenuItem name="Revenue" icon="piggy-bank" route="/revenue" />
            <MenuItem name="Reports" icon="chart-bar" route="/reports" /> */}
          </ul>
        </div>
        <ExtendedMenu extendedMenu={extendedMenu} extendMenu={extendMenu} />
      </div>
    </aside>
  );
}

function ExtendedMenu({ extendedMenu, extendMenu }) {
  return (
    <div
      className={`navigation-view ${extendedMenu ? "is-active" : ""}`}
      id="myNavigationView"
    >
      <Level mobile>
        <Left></Left>
        <Right>
          <div style={{ padding: 15 }}>
            <Delete size="large" action={() => extendMenu(!extendedMenu)} />
          </div>
        </Right>
      </Level>

      <Menu>
        <MenuItem
          extended
          name="Dashboard"
          icon="tachometer-alt"
          route="/"
          close={() => extendMenu(!extendedMenu)}
        />
        <MenuItem
          extended
          name="Merchants"
          icon="store"
          route="/merchants"
          close={() => extendMenu(!extendedMenu)}
        />
        <MenuItem
          extended
          close={() => extendMenu(!extendedMenu)}
          name="Transactions"
          icon="credit-card"
          route="/transactions"
        />
        {/* <MenuItem
          extended
          name="Sales"
          icon="user-friends"
          route="/sales"
          close={() => extendMenu(!extendedMenu)}
        /> */}
        {/* <MenuItem
          extended
          name="Revenue"
          icon="piggy-bank"
          route="/revenue"
          close={() => extendMenu(!extendedMenu)}
        />
        <MenuItem
          extended
          name="Reports"
          icon="chart-bar"
          route="/reports"
          close={() => extendMenu(!extendedMenu)}
        /> */}
      </Menu>
    </div>
  );
}
