import React, { useContext } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Link } from '@reach/router';
import { useModal } from 'react-modal-hook';

import { Button } from './bulma';
import Spinner from 'components/ui/Spinner';

import Activate from 'components/forms/Activate';

import { firebaseAppAuth } from 'services/firebase';

import UserContext from 'context/UserContext';

const PLATFORM = gql`
	query Platform {
		platform {
			id
			name
			status
			isLive
		}
	}
`;

export default function Navbar({ extendedMenu, extendMenu }) {
	const { headers, user } = useContext(UserContext);
	const { loading, data, error } = useQuery(PLATFORM, {
		context: { headers }
	});

	const [ showActivate, hideActivate ] = useModal(() => (
		<div className="modal is-active">
			<div className="modal-background" />
			<div className="modal-content">
				<Activate onClose={hideActivate} />
			</div>
		</div>
	));

	if (loading) return <Spinner />;
	if (error) return <div>{error}</div>;

	return (
		<nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
			<div className="navbar-brand">
				<div className="navbar-item">
					<span className="icon is-large has-text-light" onClick={() => extendMenu(!extendedMenu)}>
						<span className="fa-stack fa-lg">
							<i className="fas fa-square fa-stack-2x" />
							<i className="fas fa-bars fa-stack-1x has-text-grey" />
						</span>
					</span>
					<Link className="navbar-item" to="/">
						<p className="title is-4">{data && data.platform && data.platform.name}</p>
					</Link>
				</div>
			</div>

			<div id="navbarBasicExample" className="navbar-menu">
				<div className="navbar-start" />

				<div id="navbarBasicExample" className="navbar-menu">
					<div className="navbar-end">
						<div className="navbar-item">
							<button
								onClick={showActivate}
								className="button is-medium is-primary"
								aria-haspopup="true"
								aria-controls="dropdown-menu"
							>
								<span className="icon">
									<i className="fas fa-plus-circle" aria-hidden="true" />
								</span>
								<span>
									<strong>Merchant Account</strong>
								</span>
							</button>
						</div>

						<div className="navbar-item">
							<div
								className="dropdown is-hoverable is-right"
								aria-haspopup="true"
								aria-controls="dropdown-menu4"
							>
								<span className="icon dropdown-trigger">
									<span className="icon is-large" aria-hidden="true">
										<span className="fa-stack has-text-grey">
											<i className="fas fa-circle fa-stack-2x" />
											<i className="fas fa-user fa-stack-1x fa-inverse" />
										</span>
									</span>
									<i className="fas fa-angle-down" aria-hidden="true" />
								</span>

								<div className="dropdown-menu" id="dropdown-menu4" role="menu">
									<div className="dropdown-content">
										{/* <a className="dropdown-item">Notifications</a>
										<a className="dropdown-item">Settings</a> */}
										<div className="dropdown-item">
											<p>
												<small>
													<strong>{user.email}</strong>
												</small>
											</p>
											<p>
												<small>
													<strong>Last login:</strong> {user.metadata.lastSignInTime}
												</small>
											</p>
										</div>
										<hr className="dropdown-divider" />
										<a className="dropdown-item" onClick={() => firebaseAppAuth.signOut()}>
											<strong>Log Out</strong>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="navbar-item"> </div>
					</div>
				</div>
			</div>
		</nav>
	);
}
