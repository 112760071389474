import React, { useContext, useState } from 'react';

import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Hero from 'components/ui/Hero';
import List from 'components/ui/List';
import Merchant from 'components/profiles/Merchant';

import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

import UserContext from 'context/UserContext';

export default function Merchants({ itemID }) {
	const { headers } = useContext(UserContext);
	const [ tab, setTab ] = useState('active');
	const [ view, setView ] = useState('large');
	const [ item, setItem ] = useState(itemID);
	const { loading, data, error } = useQuery(MERCHANTS, {
		variables: { status: tab },
		context: { headers }
	});

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	if (item) return <Merchant id={item} />;

	return (
		<div>
			<Hero
				color="primary"
				icon="store"
				title="Merchants"
				subtitle="Account profiles by stage"
				tabs={tabs}
				activeTab={tab}
				setTab={setTab}
				view={view}
				setView={setView}
			/>
			<List type="merchants" view={view} data={data.merchants.reverse()} actions={[ setItem ]} />
		</div>
	);
}

const tabs = [
	{ title: 'new', icon: 'user-plus' },
	{ title: 'pending', icon: 'clock' },
	{ title: 'approved', icon: 'check-circle' }
];

// *****************************************
// **************** GraphQL ****************
// *****************************************

const MERCHANTS = gql`
	query Merchants($status: String) {
		merchants(status: $status) {
			id
			name
			category
			status
			isLive
			createdAt
			updatedAt
			paymentCount
			totalVolume
			avgTransaction
		}
	}
`;
