import React, { useState } from "react";

import { firebaseAppAuth, providers } from "services/firebase";

import { Formik, Form, Field } from "formik";

import { Hero } from "components/ui/bulma/layout";
import { Button, Notification } from "components/ui/bulma/elements";
import { Input } from "components/ui/bulma/form";

export default function AuthPage() {
  const [isLogin, toggleLogin] = useState(false);
  const [isReset, toggleReset] = useState(false);

  return (
    <Hero size="fullheight">
      <div className="columns is-mobile is-centered">
        <div className="column is-three-quarters">
          {isReset ? (
            <div>
              <h1 className="title">Reset Password</h1>
              <h2 className="subtitle">
                Get a secure link and set a new password
              </h2>
            </div>
          ) : !isLogin ? (
            <div>
              <h1 className="title">Create your merchant platform</h1>
              <h2 className="subtitle">
                Sell merchant accounts, collect revenue forever
              </h2>
            </div>
          ) : (
            <div>
              <h1 className="title">Sign In</h1>
              <h2 className="subtitle">Sign in into existing account</h2>
            </div>
          )}
          <br />
          <div className="columns box">
            <div className="column is-half">
              {isReset ? <Reset /> : isLogin ? <Login /> : <SignUp />}
              <br />

              {isReset || (
                <Button color="text" action={() => toggleLogin(!isLogin)}>
                  <strong>
                    {isLogin
                      ? "No account? Sign up"
                      : "Have an account? Sign in"}
                  </strong>
                </Button>
              )}
              <Button color="text" action={() => toggleReset(!isReset)}>
                <strong>{isReset ? "Back" : "Reset password"}</strong>
              </Button>
            </div>
            <div className="is-divider-vertical" data-content="OR" />
            <div className="column is-half">
              <button
                className="button is-large is-dark is-outlined"
                onClick={() =>
                  firebaseAppAuth.signInWithPopup(providers.googleProvider)
                }
              >
                <span className="icon">
                  <i className="fab fa-google" />
                </span>
                <span>Login with Google</span>
              </button>
              <br />
              <br />
              <button
                className="button is-large is-black"
                onClick={() =>
                  firebaseAppAuth.signInWithPopup(providers.githubProvider)
                }
              >
                <span className="icon">
                  <i className="fab fa-github" />
                </span>
                <span>Login with GitHub</span>
              </button>
            </div>
          </div>

          <p>© 2019 Unmerchant</p>
        </div>
      </div>
    </Hero>
  );
}

const validate = values => {
  let errors = {};

  if (!values.email) {
    errors.email = "Please enter your email!";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email))
    errors.email = "This email address is invalid";

  if (!values.password) {
    errors.password = "Password is required!";
  } else if (values.password.length < 8) {
    errors.password = "Password should be longer!";
  }

  return errors;
};

const handleAuth = async (values, { setSubmitting, setStatus, setErrors }) => {
  setSubmitting(true);

  try {
    await firebaseAppAuth.createUserWithEmailAndPassword(
      values.email,
      values.password
    );
  } catch (error) {
    if (error.code === "auth/email-already-in-use") {
      try {
        await firebaseAppAuth.signInWithEmailAndPassword(
          values.email,
          values.password
        );
      } catch (error) {
        setStatus(error.message);
      }
    } else {
      setStatus(error.message);
    }
  }

  setSubmitting(false);
};

const SignUp = () => (
  <Formik
    initialValues={{
      email: "",
      password: ""
    }}
    validate={validate}
    onSubmit={handleAuth}
  >
    {({
      status,
      isSubmitting,
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      errors
    }) => (
      <Form>
        <Field
          name="email"
          label="Business Email"
          size="large"
          icon="at"
          component={Input}
          placeholder="Email"
          autoComplete="username"
        />
        <br />
        <Field
          name="password"
          type="password"
          label="Set your password"
          size="large"
          icon="lock"
          component={Input}
          placeholder="Password"
          autoComplete="current-password"
        />
        <br />
        {status && <Notification>{status}</Notification>}
        <Button
          full
          type="submit"
          color="primary"
          size="large"
          icon="check-circle"
        >
          Create Account
        </Button>
      </Form>
    )}
  </Formik>
);
const Login = () => (
  <Formik
    initialValues={{
      email: "",
      password: ""
    }}
    validate={validate}
    onSubmit={handleAuth}
  >
    {({
      status,
      isSubmitting,
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      errors
    }) => (
      <Form>
        <Field
          label="Email"
          name="email"
          icon="at"
          size="large"
          component={Input}
          placeholder="Email"
          autoComplete="username"
        />
        <br />
        <Field
          label="Password"
          name="password"
          type="password"
          icon="lock"
          size="large"
          component={Input}
          placeholder="Password"
          autoComplete="current-password"
        />
        <br />
        {status && <Notification color="danger">{status}</Notification>}
        <Button full type="submit" color="primary" size="large" icon="key">
          Sign In
        </Button>
      </Form>
    )}
  </Formik>
);

const Reset = () => (
  <Formik
    initialValues={{
      email: ""
    }}
    validate={values => {
      let errors = {};

      if (!values.email) {
        errors.email = "Please enter your email!";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)
      )
        errors.email = "This email address is invalid";

      return errors;
    }}
    onSubmit={(values, { setSubmitting, setStatus }) => {
      try {
        firebaseAppAuth
          .sendPasswordResetEmail(values.email)
          .then(() => setStatus("Please check your email for the next steps."));
      } catch (error) {
        setStatus(error.message);
      }

      setSubmitting(false);
    }}
  >
    {({
      status,
      isSubmitting,
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      errors
    }) => (
      <Form>
        <Field
          name="email"
          label="Account Email"
          icon="at"
          size="large"
          component={Input}
          placeholder="Email"
          autoComplete="username"
        />
        <br />
        {status && <Notification>{status}</Notification>}
        <Button
          full
          type="submit"
          color="primary"
          size="large"
          icon="envelope"
          disabled={isSubmitting}
        >
          Reset Password
        </Button>
      </Form>
    )}
  </Formik>
);
