const firebaseConfig = {
  apiKey: "AIzaSyAXNNVp6266LJct8BlFaCAi9m9RGodVnXk",
  authDomain: "cardera-console.firebaseapp.com",
  databaseURL: "https://cardera-console.firebaseio.com",
  projectId: "cardera-console",
  storageBucket: "cardera-console.appspot.com",
  messagingSenderId: "392073456930",
  appId: "1:392073456930:web:ce56cdb37cf8b173"
};

export default firebaseConfig;
