import './App.sass';
import React from 'react';

import analytics from 'react-segment';

import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAppAuth } from 'services/firebase';

import Auth from 'pages/AuthPage';
import Account from './Account';

import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

const API_URL = process.env.REACT_APP_API_ROOT_URL;
const PROD = 'https://fd5dzjbsv4.execute-api.us-east-1.amazonaws.com/prod/graphql';

const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY;

analytics.default.load(SEGMENT_KEY);

const client = new ApolloClient({
	// uri: API_URL
	uri: PROD
});

export default function App() {
	const [ user, loading, error ] = useAuthState(firebaseAppAuth);

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	return <ApolloProvider client={client}>{user ? <Account /> : <Auth />}</ApolloProvider>;
}
