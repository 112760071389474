import React, { useContext, useState } from 'react';

import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { Formik, Form, Field } from 'formik';

import { Hero, Box, Button, Input } from 'components/ui/bulma/';

import UserContext from 'context/UserContext';

export default function AccountSetup() {
	const { user, headers } = useContext(UserContext);
	const [ type, setType ] = useState();
	const [ createPlatform ] = useMutation(CREATE_PLATFORM, {
		context: { headers },
		refetchQueries: [ 'CurrentUser' ]
	});

	return (
		<Hero>
			<div className="columns is-mobile is-centered">
				<div className="column is-half">
					<h1 className="title">Welcome to Unmerchant!</h1>
					<h2 className="subtitle">Let's setup your account</h2>

					<Box>
						<div className="tile is-ancestor">
							<div className="tile is-parent">
								<div className="tile is-child">
									<Formik
										initialValues={{
											name: '',
											fullName: '',
											phone: ''
										}}
										validate={validate}
										onSubmit={async ({ name, fullName, phone }, { setSubmitting, setStatus }) => {
											setSubmitting(true);

											const platformProps = {
												name,
												type
											};

											const [ firstName, lastName ] = fullName.split(' ');

											const userProps = {
												uid: user.uid,
												firstName,
												lastName,
												phone
											};

											await createPlatform({ variables: { platformProps } });
											setSubmitting(false);
										}}
									>
										{({
											status,
											isSubmitting,
											handleSubmit,
											handleChange,
											handleBlur,
											values,
											touched,
											errors
										}) => (
											<Form>
												<Field
													name="fullName"
													label="Your Full Name"
													icon="user"
													size="large"
													component={Input}
													placeholder="Full Name"
												/>

												<Field
													name="phone"
													label="Phone Number"
													icon="phone"
													size="large"
													component={Input}
													mask="(999) 999 9999"
													placeholder="Phone Number"
												/>
												<Field
													name="name"
													label={
														type === 'sme' ? (
															'Trading As'
														) : type === 'agent' ? (
															'Agency Name'
														) : (
															'Company Name'
														)
													}
													icon={
														type === 'sme' ? (
															'store'
														) : type === 'agent' ? (
															'briefcase'
														) : (
															'building'
														)
													}
													size="large"
													component={Input}
													placeholder={
														type === 'sme' ? (
															'Trading As'
														) : type === 'agent' ? (
															'Agency Name'
														) : (
															'Company Name'
														)
													}
												/>

												<hr />

												{/* <Button
														size="medium"
														icon="angle-left"
														color="white"
														action={() => setType()}
													>
														Back
													</Button> */}
												<Button block type="submit" block size="medium" icon="check-circle">
													Continue
												</Button>
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</Box>
				</div>
			</div>
		</Hero>
	);
}

const validate = (values) => {
	let errors = {};

	if (!values.name) errors.name = 'Please enter business name!';
	if (!values.fullName) errors.fullName = 'Please enter your name!';
	if (!values.phone) errors.phone = 'Please enter your phone!';

	return errors;
};

const CREATE_PLATFORM = gql`
	mutation CreatePlatform($platformProps: PlatformProps) {
		createPlatform(platformProps: $platformProps) {
			id
			name
		}
	}
`;
